import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import AddOfflineSoft from '../../components/popup/AddOfflineSoft.vue';
import { softwareOfflineSoft } from '../../data/software';
import { sendThis } from '../../data/software/softwareData';
import SoftCommonTable from '../../components/common/SoftCommonTable';
import jquery from 'jquery';
/*
* state: 0
* */
export default {
  name: 'OfflineSoft',
  data: function data() {
    return {
      accept: "",
      //限制上传文件类型
      softwareOfflineSoft: softwareOfflineSoft,
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      //获取用户数据
      // 筛选
      filter: [{
        placeholder: '适用操作系统',
        value: '',
        type: 'select',
        list: [{
          id: 'Windows',
          name: 'Windows'
        }, {
          id: 'Android',
          name: 'Android'
        }]
      }, {
        placeholder: '是否发布',
        value: '',
        type: 'select',
        list: [{
          id: 1,
          name: '已发布'
        }, {
          id: "0",
          name: '未发布'
        }, {
          id: -1,
          name: '已取消'
        }]
      }, {
        placeholder: '是否强制更新',
        value: '',
        type: 'select',
        list: [{
          id: 1,
          name: '强制更新'
        }, {
          id: "0",
          name: '不强制更新'
        }]
      }, {
        placeholder: '版本号',
        value: '',
        type: 'text',
        list: []
      }],
      //表格数据
      tableData: [],
      tableKey: 1,
      //避免数据更新表格视图不更新
      total: 0,
      //页数总数
      currentPage: parseInt(this.$route.query.currentPage ? this.$route.query.currentPage : 1),
      // 当前页数
      pageSize: parseInt(this.$route.query.pageSize ? this.$route.query.pageSize : 10),
      //每页个数
      //添加修改软件
      formFile: {
        name: '客户端',
        //万放数学教学平台
        forceUpdate: 0,
        //是否强制更新
        version: "",
        //版本号
        system: '',
        //系统
        url: "",
        //7七牛地址
        description: '',
        //描述
        state: 0 //状态：1已发布 0未发布  -1已发布已取消
      }
    };
  },

  components: {
    AddOfflineSoft: AddOfflineSoft,
    SoftCommonTable: SoftCommonTable
  },
  watch: {
    '$route': 'initData'
  },
  created: function created() {
    this.initData();
  },
  mounted: function mounted() {
    //发送当前实例
    sendThis(this);
  },
  methods: {
    //点击确定执行的方法
    confirmClick: function confirmClick(formEl) {
      var url = this.formFile.url;
      var patchUrl = this.formFile.patchUrl;
      /* axios.get(this.formFile.url+"?qhash/sha256").then((res)=>{
           console.log(res)
         }
       ).catch((err)=>{
         console.log(err)
       })*/
      var _this = this;

      // alert($)
      //
      //有补丁包，补丁包的hash值返回来在入库
      /* if(patchUrl && patchUrl!="" ){
         if(_this.formFile.patchHash && _this.formFile.patchHash!=""){
           _this.addResource(formEl);
         }
       }else{*/
      //没有补丁包，直接写入库
      //  console.log("调用页面的方法",formEl)
      _this.addResource(formEl);
      // }

      /*                    axios.get(this.formFile.url+"?qhash/sha256")
                            .then(response => {
                              alert(11111)
                              console.log(response);
                            })
                            .catch(error => {
                            });*/

      // return;
      //开始添加资源
      // this.addResource(formEl);
    },
    //重传
    edit: function edit(row) {
      this.isEdit = true;
      // console.log("编辑",row)
      this.currentRow = row;
      this.showAddResource(1, row.system);
      /*  this.resetDataFn();
        setTimeout(()=>{
          //然后在赋值
          this.structureData={...row};
          this.structureData.courseName=this.courseData.name;
          this.structureData.definitionName=this.definitionName;
          this.structureData.pid=row.parent ? row.parent.id : 0 ;
          this.structureData.roleId=this.Ls.getParseToken('nof_userInfo').roleId;
          this.structureData.filterText=this.filterText;
        },30);*/
    },
    //编辑
    editData: function editData(row) {
      this.isEdit = true;
      this.currentRow = row;
      this.showAddResource(3, row.system);
    },
    //添加资源文件
    addResource: function addResource(formEl, json) {
      var _this2 = this;
      var json = json || {};
      var requestName = "";
      if (this.formFile.id) {
        requestName = "editSoftware";
      } else {
        requestName = "addSoftware";
      }
      var postData = _objectSpread({}, this.formFile);

      // console.log("传递的数据",postData);
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            //初始化有就调用
            _this2.$refs.childRef.initFn && _this2.$refs.childRef.initFn();
            // this.$refs.childRef.dialogFormVisible=false;
            _this2.$toast.success(res.message);
            _this2.initData();
            /*if(json.formEl){
              //表单数据初始化
              json.formEl.resetFields();
            }*/

            //表单数据初始化
            // formEl.resetFields();
            // this.cancelDrawer();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    //显示添加资源文件弹框
    showAddResource: function showAddResource(isEdit, system) {
      var _this3 = this;
      if (system == "Windows") {
        this.accept = ".exe,.msi,.zip";
      } else {
        this.accept = ".apk,.zip";
      }
      if (isEdit == 1) {
        //重传
        this.$refs.childRef.showAddResource(1);
        setTimeout(function () {
          _this3.formFile = _objectSpread({}, _this3.currentRow);
          delete _this3.formFile.hash;
          delete _this3.formFile.createdAt;
          delete _this3.formFile.url;
          delete _this3.formFile.size;
          delete _this3.formFile.hash;
          delete _this3.formFile.patchUrl;
          delete _this3.formFile.patchSize;
          delete _this3.formFile.patchHash;
        }, 30);
      } else if (isEdit == 3) {
        //修改编辑
        this.$refs.childRef.showAddResource(isEdit);
        setTimeout(function () {
          _this3.formFile = _objectSpread({}, _this3.currentRow);
          delete _this3.formFile.createdAt;
        }, 30);
      } else {
        //默认选中课间
        //初始化type资源类型
        // this.initType();
        this.formFile.system = system;
        this.$refs.childRef.showAddResource();
      }
    },
    //筛选功能
    search: function search(filterArr) {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: "getSoftwareList",
        data: {
          state: filterArr[1].value,
          system: filterArr[0].value,
          forceUpdate: parseInt(filterArr[2].value),
          version: filterArr[3].value,
          // userId:this.userInfo.userId,
          // roleId:this.userInfo.roleId,
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10,
          pageIndex: this.$route.query.currentPage ? this.$route.query.currentPage : 1
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.tableData = res.data.data;
            _this4.total = res.data.total;
            for (var i = 0; i < _this4.tableData.length; i++) {
              var value = _this4.tableData[i];
              if (value.state == 1) {
                _this4.tableData[i].disabledSoft = true;
              }
            }
            _this4.tableKey++;
          } else {
            _this4.$message.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.filter[2].value = "";
      this.initData();
    },
    //初始化获取数据
    initData: function initData() {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: 'getSoftwareList',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10,
          pageIndex: this.$route.query.currentPage ? this.$route.query.currentPage : 1
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this5.tableData = res.data.data;
            _this5.total = res.data.total;
            for (var i = 0; i < _this5.tableData.length; i++) {
              var value = _this5.tableData[i];
              if (value.state == 1) {
                _this5.tableData[i].disabledSoft = true;
              }
            }
            _this5.tableKey++;
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    },
    //确认弹框的内容
    dialogContent: function dialogContent(json, row) {
      // console.log("弹框",json,row)
      if (row.state == 1) {
        //取消发布
        json.title = "取消发布";
        json.content = "确定取消发布 " + row.name + " 吗？";
      } else if (row.state == 0) {
        //发布
        json.title = "发布";
        json.content = "确定发布 " + row.name + " 吗？";
      }
      return json;
    },
    //发布或者下架
    changeState: function changeState(value) {
      var _this6 = this;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        id: value.id
      };
      if (value.state == 1) {
        //发布成功的下架
        postData.state = -1;
      } else if (value.state == 0) {
        //未发布的是发布
        postData.state = 1;
      } else {
        //已发布过的且下架了的不允许在操作
        this.$toast.error("已经发布过的不能再发布了哦！");
        return;
      }
      this.service.axiosUtils({
        requestName: 'changeSoftwareState',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this6.$toast.success(res.message);
          } else {
            _this6.$toast.error(res.message);
          }
          _this6.initData();
        }
      });
    }
  }
};